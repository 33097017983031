module.exports = {
  SiteTitle: 'James Heywood',
  Sitelogo: '#',
  SiteLogoText: 'jdheywood',
  SiteAuthor: 'James Heywood',
  SiteDescription: 'Technology agnostic software professional',
  SiteImage: 'https://jdheywood.dev/meta-image.png',
  TwitterAccount: '@jdheywood',
  defaultDescription: 'Full stack pancake', 
  githubApiQuery: `query ($number_of_repos: Int!) {
    viewer {
      name
      avatarUrl
      isHireable
      resourcePath
      repositories(last: $number_of_repos, privacy: PUBLIC, orderBy: { field: STARGAZERS, direction:ASC } ) {
        nodes {
          name
          description
          homepageUrl
          forkCount
          createdAt
          updatedAt
          resourcePath
          languages(last: 1, orderBy: { field: SIZE, direction:ASC } ) {
            edges {
              node {
                name
                color
              }
            }
          }
          licenseInfo {
            name
          }
          stargazers {
            totalCount
          }
        }
      }
    }
  }`,
  githubApiVariables: {
    number_of_repos: 20,
  }, 
  SiteSocialLinks: {
    twitter: 'https://twitter.com/jdheywood',
    github: 'https://github.com/jdheywood',
    linkedin: 'http://www.linkedin.com/in/jdheywood',
  },
  SiteAddress: {
    city: 'Eastbourne',
    region: 'South East',
    country: 'UK',
    zipCode: 'BN26',
  },
  SiteContact: {
    email: 'jdheywood@gmail.com',
    phone: '+44 7881 757 976',
  },
  SiteCopyright: '2021',
};
