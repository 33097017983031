  
export default {
  primary: "#2d9dff",
  inverse: "#FFE74C",
  // colors
  darkGrey: "#333447",
  black: "#000",
  minionYellow: "#FFE74C",
  fieryRose: "#FF5964",
  white: "#FFFFFF",
  screaminGreen: "#6BF178",

  // grid
  width: "96%",
  wrapperWidth: "65rem",
  gutter: "4%",
  breakpointPhone: "850px",
  breakpointTablet: "1024px",
  breakpointLaptop: "1200px",
  breakpointLarge: "60em" // 960px
}